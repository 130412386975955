import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import moment from 'moment'
import Seo from '../components/Seo'
import HeroSection from '../components/HeroSection'

const Bold = ({ children }) => <span className="font-semibold">{children}</span>
const Text = ({ children }) => <p className="md:text-xl align-center mb-5 ">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content[0].marks[0]?.type === 'code'  ) {
        return <div className='relative flex flex-col'><div className='flex flex-grow flex-col'><div className='flex flex-col items-start  whitespace-pre-wrap'><div className='markdown prose w-full break-words dark:prose-invert dark'><pre><div className='bg-black mb-4 rounded-md'><div className='p-4 overflow-y-auto'><code className='!whitespace-pre hljs language-css'>{node.content[0].value}</code></div></div></pre></div></div></div></div>;
      }
    
      return <Text>{children}</Text>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
        // Extract videoId from the URL
        const match = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
          node.data.uri
      )
      const videoId =
          match && match[7].length === 11 ? match[7] : null
        if (node.data.uri.indexOf('youtube.com') !== -1) {
          return (
            <section className="video-container">
             <iframe
                  className="video"
                  title={`https://youtube.com/embed/${videoId}`}
                  src={`https://youtube.com/embed/${videoId}`}
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              />
            </section>
          );
        } 
        else{
          return (
            <a href={uri} className="underline text-amber-700">
              {children}
            </a>
          )
        }
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const url = node?.data?.target?.file?.url
      const alt = node?.data?.target?.title
      if(node.data.target.file.url){
          return <img className='mb-5' alt={alt} src={url} />
      }
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className='md:text-2xl text-xl mt-10 mb-3 font-semibold first:mt-0'>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className='md:text-xl text-lg mt-10 mb-3 font-semibold'>{children}</h3>
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className='list-decimal pl-4'>{children}</ol>
    },
    [BLOCKS.CODE]: (node, children) => {
      return <code className='border-2'>{children}</code>
    },
  },
}

const Blog = ({data}) => {
    const {title, content, createdAt, excerpt , featuredImage} = data.contentfulBlog
  return (
    <Layout>
    <Seo title={title} description={excerpt} imageUrl={featuredImage?.url}/>
    <HeroSection subtitle={title} imageUrl={featuredImage?.url}/>
    <div className="w-full  max-w-screen-xl mx-auto md:px-8 p-4">
      <div className='gap-8 md:py-10 py-3'>
          <div className=''>
              {/* <div className="flex justify-between">
                  <span className="text-sm">{moment(createdAt).format("MMMM Do YYYY")}</span>
              </div> */}
              {/* <h2 className="md:text-5xl text-2xl font-semibold mt-1 mb-10">{title}</h2> */}
              {renderRichText(content, options)}
          </div>
        </div>
    </div>
    </Layout>
  )
}

export const singleblogquery = graphql`
query getSingleBlog($title: String) {
  contentfulBlog(title: {eq: $title}) {
    title
    id
    contentful_id
    createdAt
    excerpt
    featuredImage {
      url
    }
    content {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          gatsbyImageData
          id
          title
          file {
          url
          }
        }
        title
      }
    }
  }
}
`


export default Blog
